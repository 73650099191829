import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../layout'
import { SEO, Products, MainUpdate, Update } from '../../components'

import PlatformUpdatesOgImage from '../../assets/images/platform-updates-seo-image.png'

export const query = graphql`
  query UpdatesQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { nin: ["hackathon-terms", "rule"] } } }
      limit: 4
    ) {
      nodes {
        fields {
          index
        }
        frontmatter {
          slug
          author
          title
          date(formatString: "DD MMMM, YYYY")
          ogimage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        excerpt(pruneLength: 250)
      }
    }
  }
`

const Updates = ({ data }) => {
  const updates = data.allMarkdownRemark.nodes.map(node => ({
    ...node.frontmatter,
    excerpt: node.excerpt,
    index: node.fields.index,
  }))
  const lastUpdate = updates[0]
  return (
    <Layout>
      <SEO
        title={"LiveChat Platform Updates – See what we're up to!"}
        description={
          'Track all product updates and programming notes in the LiveChat Platform.'
        }
        image={PlatformUpdatesOgImage}
      />
      <div className="u-Pb-md u-Pt-xl u-bg-black-2">
        <section className="o-container u-Mb-md">
          <h3 className="u-text-p2">Platform Updates</h3>
        </section>
        <MainUpdate
          slug={lastUpdate.slug}
          title={lastUpdate.title}
          author={lastUpdate.author}
          date={lastUpdate.date}
          image={lastUpdate.ogimage}
          index={lastUpdate.index}
        />
      </div>
      <div className="u-bg-black-3 u-Pb-md md:u-Pb-xl u-Pt-md md:u-Pt-xl">
        <Products
          label="Categories"
          columns={2}
          items={[
            {
              name: 'Product Updates',
              description:
                "Learn what's new in the LiveChat products family. See all the recent technical releases and updates.",
              to: '/updates/product/',
              cta: 'See Product Updates',
            },
            {
              name: 'Programming Notes',
              description:
                "We've been building software solutions for 18 years and counting. See a few bits on programming from our teams.",
              to: '/updates/programming/',
              cta: 'See Programming Notes',
            },
            {
              name: 'UX Notes',
              description:
                'We treat User Experience seriously at LiveChat. See how we approach various UX/UI challenges while building our products.',
              to: '/updates/ux/',
              cta: 'See UX Notes',
            },
            {
              name: 'All Updates',
              description:
                'Browse all the Platform Updates. See what our teams are up to, what recent changes were released and what to expect in future!',
              to: '/updates/all/',
              cta: 'See all Platform Updates',
            },
          ]}
        />
      </div>
      <div className="u-Pb-md u-Pt-xl u-bg-black-2">
        <section className="o-container u-Mb-md">
          <h3 className="u-text-p3">Most recent</h3>
        </section>
        {updates.slice(1).map((node, i) => {
          const { slug, title, author, date, excerpt, ogimage, index } = node
          return (
            <Update
              key={`${node.title}-${i}`}
              slug={slug}
              title={title}
              author={author}
              date={date}
              excerpt={excerpt}
              image={ogimage}
              index={index}
              marginBottom
            />
          )
        })}
      </div>
    </Layout>
  )
}

Updates.propTypes = {
  data: PropTypes.object,
}

export default Updates
